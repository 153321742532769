import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { FaQuoteLeft } from "react-icons/fa";

const testimonials = [
  {
    clientName: "Mahuli Laghubitta Bittiya Sanstha Limited",
    clientImage: "https://mslbsl.com.np/images/logo.png", // Update with actual logo path
    highlight: "It was a very nice experience",
    fullTestimonial:
      "Uranus Tech Nepal Pvt. Ltd., has been an invaluable partner for our financial institution. Their mobile banking platform has revolutionized the way we serve our customers. The user-friendly interface, robust security measures, responsive support and continuous innovation have strengthened our position in the market. Thanks to them, we've elevated our digital banking services to a new level.",
  },
  {
    clientName: "Sana Kisan Bikas Laghubitta Bittiya Sanstha Ltd(SKBBL)",
    clientImage:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTPoCHr8K2zsxFzW9NzBFPowV7I5YiwIE6sQ&s",
    highlight:
      "They brought clarity and modern design to a traditionally complex sector.",
    fullTestimonial:
      "Our collaboration with the team resulted in a digital platform that not only reflects our institutional values but also improves user accessibility and trust. We appreciated their professional and transparent process.",
  },
  {
    clientName: "Deposit and Credit Guarantee Fund (DCGF)",
    clientImage:
      "https://dcgf.gov.np/front/nep/images/deposit-and-credit-guarantee-fund-small-logo.jpg",
    highlight: "A seamless digital transformation experience.",
    fullTestimonial:
      "The developers took the time to understand our organization and built a responsive, informative website. Their structured process and communication made everything smooth from start to finish.",
  },
  {
    clientName: "NIC ASIA LAGHUBITTA BITTIYA SANSTHA LTD",
    clientImage:
      "https://is1-ssl.mzstatic.com/image/thumb/Purple211/v4/a7/5c/7a/a75c7aa6-af62-cca0-6117-02d8ac4435b0/AppIcon-0-0-1x_U007emarketing-0-6-0-0-85-220.png/512x512bb.jpg",
    highlight: "Highly efficient and results-driven.",
    fullTestimonial:
      "Their work helped us streamline our online services and present a more refined brand identity. The website performance and reliability have been impressive since launch.",
  },
  // {
  //   clientName: "Embassy of India, Kathmandu",
  //   clientImage:
  //     "https://www.indembkathmandu.gov.in/themes/w3cms/embassy-of-india/assets/images/logo.png",
  //   highlight: "They handled our requirements with precision and elegance.",
  //   fullTestimonial:
  //     "It was important for us to present content clearly and securely. Their team delivered an intuitive interface that serves both Nepali and Indian stakeholders effectively.",
  // },
  {
    clientName: "Mahuli Laghubitta",
    clientImage: "https://mslbsl.com.np/images/logo.png",
    highlight: "Empowered our operations with modern tech.",
    fullTestimonial:
      "They listened carefully and translated our needs into a dynamic site. We've since received positive feedback from both customers and staff regarding usability.",
  },
];

const Testimonials = () => {
  const [index, setIndex] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    }, 7000);
    return () => clearInterval(interval);
  }, [index]);

  const testimonial = testimonials[index];

  return (
    <motion.section
      className=" px-4 md:px-10 lg:px-20 py-10 relative"
      style={{
        backgroundImage:
          "url('https://img.freepik.com/free-vector/white-background-with-blue-tech-hexagon_1017-19366.jpg')",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {/* Semi-transparent overlay */}
      <div className="absolute inset-0 bg-gray-100 bg-opacity-80"></div>
      <div className="relative z-10 max-w-6xl mx-auto">
        <motion.h1
          className="font-semibold text-primary text-xl md:text-2xl  lg:text-3xl text-center mb-2"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          What Our Clients Say
        </motion.h1>
        <motion.p
          className="text-center text-gray-600 text-sm sm:text-base md:text-md mb-2"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7 }}
        >
          We take pride in delivering great service to our clients. Read what
          they have to say about our work.
        </motion.p>

        <motion.div
          key={index}
          className="flex justify-center mb-12 gap-4 "
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7 }}
        >
          <div className="text-black text-opacity-20 text-5xl font-serif hidden md:block">
            <FaQuoteLeft></FaQuoteLeft>
          </div>
          <div className="flex flex-row items-start gap-4 mt-5 ">
            <div className="bg-gray-50 rounded-lg shadow-xl py-5 px-8 max-w-4xl">
              {/* Client logo */}
              <div className="flex justify-center mb-3 ">
                <div className="bg-white rounded-full p-2 w-24 h-24 flex items-center justify-center">
                  <img
                    src={testimonial.clientImage}
                    alt={`${testimonial.clientName} logo`}
                    className="max-w-full max-h-full object-contain rounded-full"
                  />
                </div>
              </div>
              <p className="text-center text-red-500 font-semibold text-sm sm:text-base md:text-md mb-2">
                {testimonial.clientName}
              </p>
              <h2 className="md:text-xl font-bold text-center mb-4  text-sm sm:text-base md:text-md ">
                "{testimonial.highlight}"
              </h2>
              <p className="text-gray-800 md:text-lg text-center mb-8  text-sm sm:text-base md:text-md">
                {testimonial.fullTestimonial}
              </p>
            </div>
          </div>
        </motion.div>

        {/* Dot Indicators */}
        <div className="mt-8 flex space-x-4 justify-center">
          {testimonials.map((_, i) => (
            <button
              key={i}
              className={`w-3 h-3 rounded-full transition-all cursor-pointer ${
                i === index ? "bg-primary scale-125 shadow-lg" : "bg-gray-300"
              }`}
              onClick={() => setIndex(i)}
            />
          ))}
        </div>
      </div>
    </motion.section>
  );
};

export default Testimonials;
