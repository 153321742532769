import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import computer from "../assets/Computer(1).png";
import cbs from "../assets/cbs.png";
import { FaCheck } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import TrustedBy from "./TrustedBy";

const products = [
  {
    name: "Core Banking Solution (CBS)",
    description:
      "A comprehensive banking solution implemented in over 30 financial institutions.",
    imageUrl: cbs,
    features: [
      "Multi-institution Support",
      "Real-time Transactions",
      "Security Compliance",
      "Automated Reports",
      "Customer Management",
    ],
    _id: "CBS",
  },
  {
    name: "Human Resource Management",
    description:
      "An efficient HR management system to streamline HR processes.",
    imageUrl: computer,
    features: [
      "Payroll Automation",
      "Attendance Tracking",
      "Employee Database",
      "Performance Reviews",
      "Leave Management",
    ],
    _id: "UranusHRM",
  },
  {
    name: "Digital Field Application",
    description: "A mobile solution for field data collection and management.",
    imageUrl: computer,
    features: [
      "Offline Data Collection",
      "GPS Tracking",
      "Custom Forms",
      "Instant Reporting",
      "User Access Control",
    ],
    _id: "DFA",
  },
  {
    name: "mPassbook",
    description:
      "A digital passbook application for convenient account management.",
    imageUrl: computer,
    features: [
      "Real-time Updates",
      "Secure Access",
      "Transaction History",
      "Multi-bank Support",
      "PDF Export",
    ],
    _id: "mPassbook",
  },
  {
    name: "Fixed Assets Management Information System",
    description:
      "A system developed for efficient pension distribution by the Indian Embassy.",
    imageUrl: computer,
    features: [
      "Asset Tracking",
      "Depreciation Calculation",
      "Audit Logs",
      "User Role Management",
      "Automated Reports",
    ],
    _id: "FAMIS",
  },
];

export default function ProductScroller() {
  const [index, setIndex] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % products.length);
    }, 7000);
    return () => clearInterval(interval);
  }, [index]);

  const product = products[index];

  return (
    <motion.section
      className="px-4 md:px-10 lg:px-20 py-10 relative"
      style={{
        backgroundImage:
          "url('https://img.freepik.com/free-vector/white-background-with-blue-tech-hexagon_1017-19366.jpg')",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="absolute inset-0 bg-gray-100 bg-opacity-80"></div>
      <div className="relative z-10">
        <TrustedBy />
        <div
          key={index}
          className="grid grid-cols-1 lg:grid-cols-2 gap-7 items-center"
        >
          {/* Text Section */}
          <div>
            <motion.div
              className="flex flex-col"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1 }}
            >
              <h1 className="font-semibold text-primary text-md  lg:text-2xl text-start mb-2">
                {product.name}
              </h1>
              <p className="text-start text-gray-600 text-sm sm:text-base md:text-sm lg:text-md mb-2">
                {product.description}
              </p>
            </motion.div>

            {product.features.map((feature, i) => (
              <motion.div
                key={i}
                className="relative overflow-hidden"
                initial={{ x: -50, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                transition={{ delay: 0.3 * i, duration: 0.5 }}
              >
                <h2 className="text-sm sm:text-base md:text-sm lg:text-md mb-2 mt-2 flex items-center gap-2 text-start text-gray-600">
                  <FaCheck className="text-primary text-base sm:text-lg" />{" "}
                  {feature}
                </h2>
              </motion.div>
            ))}

            <motion.button
              initial={{ x: -50, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{
                delay: 0.1 * product.features.length,
                duration: 0.2,
                ease: "easeOut",
              }}
              onClick={() => navigate("/products")}
              className="mt-4 text-sm sm:text-base px-4 py-2 text-center rounded-lg text-primary font-semibold outline outline-2 outline-primary hover:text-white hover:bg-teal-600 transition-transform duration-300 hover:scale-105"
            >
              Explore More
            </motion.button>
          </div>

          {/* Image Section */}
          <motion.div
            className="relative w-full flex justify-center mx-auto"
            initial={{ x: 100, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.8 }}
          >
            <img
              src={product.imageUrl}
              alt={product.name}
              className="w-[70%] sm:w-[80%] md:w-[90%] lg:w-[95%] lg:max-h-[250px] object-contain transition-transform duration-500 transform hover:scale-110 rounded-xl"
            />
          </motion.div>
        </div>

        {/* Dot Indicators */}
        <div className="mt-8 flex space-x-4 justify-center">
          {products.map((_, i) => (
            <button
              key={i}
              className={`w-3 h-3 rounded-full transition-all cursor-pointer ${
                i === index ? "bg-primary scale-125 shadow-lg" : "bg-gray-300"
              }`}
              onClick={() => setIndex(i)}
            />
          ))}
        </div>
      </div>
    </motion.section>
  );
}
