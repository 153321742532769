import React from "react";
import { FaCheck } from "react-icons/fa";
import { motion } from "framer-motion";
import playStore from "../assets/downloadAppButtonAndroid.png";
import appStore from "../assets/downloadAppButtonIOS.png";
import appBanner from "../assets/appBanner.png";

const DownloadApp = () => {
  return (
    <motion.section
      className="px-4 md:px-10 lg:px-20 py-10 relative"
      style={{
        backgroundImage:
          "url('https://img.freepik.com/free-vector/white-abstract-background_23-2148882948.jpg?t=st=1743763226~exp=1743766826~hmac=40680f0fa022f2bb427b38ba426d955a7cb87c06b4b70c84c6f2752b76d4d8ab&w=996')",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="absolute inset-0 bg-gray-950 bg-opacity-20"></div>
      {/* Semi-transparent overlay */}

      {/* Content Container */}
      <div className="relative z-20">
        <div className="grid grid-cols-1 md:grid-cols-2 items-center">
          <div>
            <motion.div
              className="flex flex-col justify-center items-center gap-4 "
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1 }}
            >
              <h1 className="font-bold text-black text-xl md:text-2xl  lg:text-3xl text-center ">
                Download Our App Now
              </h1>
              <div className="flex flex-row justify-center gap-4  cursor-pointer">
                <a
                  href="https://apps.apple.com/us/app/ura-events/id6740744398?platform=iphone"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => e.stopPropagation()}
                >
                  <img
                    src={appStore}
                    alt="App Store"
                    className="w-[200px] lg:w-[250px] h-auto"
                  />
                </a>

                <a
                  href={
                    "https://play.google.com/store/apps/dev?id=5087439032110048837&hl=en"
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-[200px] lg:w-[250px] h-auto"
                >
                  <img src={playStore} alt="Play Store" />
                </a>
              </div>
            </motion.div>
          </div>
          <motion.div
            animate={{ scale: [1, 1.05, 1] }}
            transition={{
              duration: 4,
              repeat: Infinity,
              repeatType: "loop",
              ease: "easeInOut",
            }}
          >
            <motion.div
              className="relative w-full flex justify-center mx-auto"
              initial={{ x: 100, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.8 }}
            >
              <img
                src={appBanner}
                alt="App Banner"
                className="h-64 md:h-80 lg:h-96 mt-10 md:mt-0"
              />
            </motion.div>
          </motion.div>
        </div>
      </div>
    </motion.section>
  );
};

export default DownloadApp;
