import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import logo from "../assets/logo.png";

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();

  const toggleButton = () => {
    setMenuOpen(!menuOpen);
  };

  const getNavLinkClass = ({ isActive }) =>
    `font-light hover:cursor-pointer transition-colors duration-200 ${
      isActive
        ? "text-primary font-bold"
        : "text-secondaryText hover:text-primary"
    }`;

  return (
    <div className="sticky top-0 z-50 w-full bg-white shadow">
      {/* Navbar */}
      <nav className="bg-white flex items-center justify-between px-5 py-3">
        {/* Logo */}
        <NavLink to="/" className="flex items-center space-x-3">
          <img
            src={logo}
            className="h-10 md:h-14 lg:h-16 transition-transform duration-300"
            alt="Uranus Logo"
          />
        </NavLink>

        {/* Desktop Links */}
        <div className="hidden sm:flex text-xs md:text-sm lg:text-base space-x-6">
          <NavLink to="/" className={getNavLinkClass}>
            Home
          </NavLink>
          <NavLink to="/products" className={getNavLinkClass}>
            Our Portfolio
          </NavLink>
          <NavLink to="/about" className={getNavLinkClass}>
            About Us
          </NavLink>
          <NavLink to="/careers" className={getNavLinkClass}>
            Careers
          </NavLink>
        </div>

        {/* Sign In Button */}
        <div className="hidden sm:flex">
          <NavLink
            to="/login"
            className={`flex items-center px-3 py-1 gap-2 border border-primary text-sm font-medium rounded-full transition-colors duration-200 ${
              location.pathname === "/login"
                ? "bg-primary text-white"
                : "text-primary hover:bg-primary hover:text-white"
            }`}
          >
            <SupervisedUserCircleIcon
              className={`${
                location.pathname === "/login" ? "text-white" : ""
              }`}
            />
            <span>Sign In</span>
          </NavLink>
        </div>

        {/* Mobile Menu Toggle */}
        <div
          className="sm:hidden text-2xl cursor-pointer"
          onClick={toggleButton}
        >
          <i className={`fa ${menuOpen ? "fa-times" : "fa-bars"}`}></i>
        </div>
      </nav>

      {/* Mobile Fullscreen Menu */}
      {menuOpen && (
        <div className="fixed inset-0 bg-white z-50 flex flex-col px-5 py-8 space-y-6 shadow-lg transition-all duration-300">
          {/* Close Button */}
          <div className="flex justify-between items-center">
            <a href="/" className="flex items-center">
              <img src={logo} className="h-10" alt="Uranus Logo" />
            </a>
            <i
              className="fa fa-times text-2xl cursor-pointer"
              onClick={toggleButton}
            ></i>
          </div>

          {/* Menu Items */}
          <NavLink
            to="/"
            className="block text-lg font-medium hover:text-primary"
            onClick={toggleButton}
          >
            Home
          </NavLink>
          <NavLink
            to="/products"
            className="block text-lg font-medium hover:text-primary"
            onClick={toggleButton}
          >
            Our Portfolio
          </NavLink>
          <NavLink
            to="/testimonials"
            className="block text-lg font-medium hover:text-primary"
            onClick={toggleButton}
          >
            Testimonials
          </NavLink>
          <NavLink
            to="/about"
            className="block text-lg font-medium hover:text-primary"
            onClick={toggleButton}
          >
            About Us
          </NavLink>
          <NavLink
            to="/careers"
            className="block text-lg font-medium hover:text-primary"
            onClick={toggleButton}
          >
            Careers
          </NavLink>
          <NavLink
            to="/login"
            className="block text-lg font-medium hover:text-primary"
            onClick={toggleButton}
          >
            Sign In
          </NavLink>

          {/* Contact Info for Mobile */}
          <div className="mt-6 text-sm">
            <div className="flex items-center gap-2">
              <LocalPhoneIcon />
              <span>01-5244242</span>
            </div>
            <div className="flex items-center gap-2 mt-2">
              <EmailIcon />
              <span>info@uranustechnepal.com</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
