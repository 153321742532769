import React from "react";
import { useParams, Navigate, NavLink } from "react-router-dom";
import toast from "react-hot-toast";
import { motion } from "framer-motion";
import {
  CheckCircle2,
  ShieldCheck,
  Rocket,
  Layers,
  Bolt,
  Globe2,
} from "lucide-react";
import projectData from "../data/projectData";

const featureIcons = [CheckCircle2, ShieldCheck, Rocket, Layers, Bolt, Globe2];

const heroVariants = {
  hidden: { opacity: 0, y: -20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
};

const containerVariants = {
  hidden: {},
  visible: { transition: { staggerChildren: 0.1 } },
};

const itemVariants = {
  hidden: { opacity: 0, y: 10 },
  visible: { opacity: 1, y: 0 },
};

const ProjectDetails = () => {
  const { projectId } = useParams();
  const project = projectData[0][projectId];

  if (!project) {
    toast.error("Project not found.");
    return <Navigate to="/" />;
  }

  return (
    <div
      className="flex flex-col w-full min-h-screen relative"
      style={{
        backgroundImage:
          "url('https://img.freepik.com/free-vector/white-background-with-blue-tech-hexagon_1017-19366.jpg')",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundAttachment: "fixed",
      }}
    >
      {/* Optional semi-transparent overlay for better readability */}
      <div className="absolute inset-0 bg-white bg-opacity-70"></div>

      {/* Main Content Section */}
      <div className="relative z-10 flex-grow ">
        <section className="pt-8 md:pt-12 px-4 ">
          <div className="container md:max-w-[90%]  mx-auto space-y-8">
            {/* Project Info Section */}
            <motion.section className="px-2">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 md:gap-10 items-center">
                {/* Text Section */}
                <motion.div
                  className="flex flex-col"
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ duration: 1 }}
                >
                  <h1 className="text-primary text-xl md:text-2xl font-semibold mb-3">
                    {project.name}
                  </h1>
                  <p className="text-gray-600 text-sm md:text-base mb-6">
                    {project.description}
                  </p>

                  {project.shortFeatures?.map((feature, i) => {
                    const Icon = featureIcons[i % featureIcons.length];
                    return (
                      <motion.div
                        key={i}
                        className="flex items-start gap-3 mb-4"
                        initial={{ x: -50, opacity: 0 }}
                        whileInView={{ x: 0, opacity: 1 }}
                        transition={{ delay: 0.2 * i, duration: 0.4 }}
                      >
                        <Icon className="text-indigo-600 w-5 h-5 mt-0.5 flex-shrink-0" />
                        <p className="text-sm md:text-base text-gray-600">
                          {feature}
                        </p>
                      </motion.div>
                    );
                  })}
                </motion.div>

                {/* Image Section */}
                <motion.div
                  className="relative w-full flex justify-center"
                  initial={{ x: 100, opacity: 0 }}
                  whileInView={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.8 }}
                  animate={{ scale: [1, 1.02, 1] }}
                >
                  <div className="rounded-xl md:rounded-2xl lg:rounded-3xl overflow-hidden">
                    <img
                      src={project.imgUrl}
                      alt={project.name}
                      className="w-4/5 md:w-[90%] lg:w-[95%] max-h-[250px] object-contain rounded-xl md:rounded-2xl lg:rounded-3xl transition-transform duration-500 hover:scale-105 bg-white bg-opacity-70 "
                    />
                  </div>
                </motion.div>
              </div>
            </motion.section>

            {/* Long Features Section */}
            {project.longFeatures?.length > 0 && (
              <motion.div
                className="space-y-4 mt-8"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={containerVariants}
              >
                {project.longFeatures.map((feat, idx) => {
                  const parts = feat.split(":");
                  return (
                    <motion.div
                      key={idx}
                      variants={itemVariants}
                      className="flex items-start gap-3 p-4 border border-gray-200 rounded-lg bg-white bg-opacity-80 hover:bg-opacity-90 transition-colors shadow-sm"
                    >
                      <CheckCircle2 className="flex-shrink-0 self-start text-indigo-600 w-5 h-5 mt-0.5 md:w-6 md:h-6" />
                      {parts.length > 1 ? (
                        <p className="text-sm md:text-base text-gray-700">
                          <span className="font-semibold text-gray-900">
                            {parts[0]}:
                          </span>{" "}
                          {parts.slice(1).join(":")}
                        </p>
                      ) : (
                        <p className="text-sm md:text-base text-gray-700">
                          {feat}
                        </p>
                      )}
                    </motion.div>
                  );
                })}
              </motion.div>
            )}
          </div>
        </section>

        {/* CTA Section */}
        <section className="pt-3 md:pt-6 px-4">
          <div className="container mx-auto text-center max-w-2xl">
            <motion.div
              className="bg-white bg-opacity-80 rounded-xl p-6 "
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.6 }}
            >
              <motion.h3
                initial={{ opacity: 0, y: 10 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.4 }}
                className="text-lg md:text-xl font-medium text-gray-800"
              >
                Ready to dive deeper into our solutions?
              </motion.h3>
              <motion.p
                initial={{ opacity: 0, y: 10 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.4, delay: 0.2 }}
                className="mt-3 text-sm md:text-base text-gray-600"
              >
                Discover more about our innovative software solutions and how
                they can transform your business.
              </motion.p>
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.4, delay: 0.4 }}
                className="mt-6"
              >
                <NavLink to="/products">
                  <button className="bg-indigo-600 hover:bg-indigo-700 text-white text-sm md:text-base px-6 py-3 rounded-md transition-colors shadow-sm hover:shadow">
                    Explore All Projects
                  </button>
                </NavLink>
              </motion.div>
            </motion.div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ProjectDetails;
