import React, { useState } from "react";
import DashboardAnimation from "../animation_component/DashboardAnimation";
import { useTypewriter } from "react-simple-typewriter";
import img1 from "../assets/27001 UKAS PNG.png";
import img2 from "../assets/certificate.png";

const Hero = () => {
  const [text] = useTypewriter({
    words: [
      "Core Banking Solution (CBS)",
      "Digital Field Application (DFA)",
      "Digital mPassbook",
      "Bulk SMS",
      "Uranus Cloud",
    ],
    loop: true,
    typeSpeed: 80,
    deleteSpeed: 50,
    delaySpeed: 1500,
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div className="flex flex-col lg:flex-row items-center justify-between px-4 sm:px-6 md:px-10 lg:px-16 py-8 bg-gray-50">
      {/* Left Section */}
      <div className="w-full lg:w-1/2 text-center lg:text-left">
        <h1 className="text-primary font-semibold text-sm sm:text-base lg:text-lg mb-1">
          Empowering Finance through Innovation
        </h1>
        <h3 className="hidden lg:block text-black font-bold text-4xl leading-snug">
          Our Expertise to Develop and Provide
        </h3>
        <h3 className="hidden lg:block text-black font-bold text-4xl leading-snug">
          Software for Banking Institutions
        </h3>

        <h2 className="text-primary font-semibold text-base sm:text-lg lg:text-3xl mt-2">
          {text}&nbsp;
        </h2>

        {/* Description (hidden on very small screens) */}
        <p className="hidden sm:block text-secondarytext text-sm sm:text-base lg:text-lg mt-4 text-justify tracking-wide">
          Revolutionising the Banking Industry via Forward-Thinking Technology
          and Cutting-Edge Information Systems, enabling organisations to make a
          bigger splash in a dynamic financial environment.
        </p>

        {/* Buttons Section */}
        <div className="flex flex-col sm:flex-row items-center justify-center lg:justify-start mt-6 gap-3 sm:gap-4">
          <button className="w-full sm:w-auto bg-primary text-white font-semibold text-sm sm:text-base px-4 sm:px-6 py-2 sm:py-3 rounded-full transition-transform transform hover:scale-105">
            Learn More
          </button>
          <button className="w-full sm:w-auto border-2 border-primary text-primary font-semibold text-sm sm:text-base px-4 sm:px-6 py-2 sm:py-3 rounded-full transition-transform transform hover:scale-105 hover:bg-primary hover:text-white">
            Contact Us
          </button>
        </div>

        {/* Certification Label */}
        <h3
          className="text-secondarytext font-bold text-sm sm:text-base md:text-lg mt-6 cursor-pointer relative group hover:text-primary"
          onClick={openModal}
        >
          ISO 27001:2022 Certified Company
          <span className="absolute left-1 transform -translate-x-1 translate-y-1 w-max bg-gray-700 text-white text-sm rounded-lg py-1 px-3 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
            View Certification
          </span>
        </h3>
      </div>

      {/* Right Section */}
      <div className="w-full lg:w-1/2 mt-6 lg:mt-0 flex items-center justify-center">
        <DashboardAnimation />
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50 px-4">
          <div className="bg-white rounded-lg overflow-hidden shadow-lg p-5 w-full max-w-2xl">
            <h2 className="text-lg sm:text-xl font-bold mb-4">
              ISO 27001:2022 Certification
            </h2>
            <div className="flex flex-col sm:flex-row justify-center items-center gap-4">
              <img
                src={img1}
                alt="Certification 1"
                className="w-40 h-40 object-contain rounded-md"
              />
              <img
                src={img2}
                alt="Certification 2"
                className="w-40 h-40 object-contain rounded-md"
              />
            </div>
            <button
              className="mt-6 bg-success text-white px-4 py-2 rounded-md hover:bg-primary transition"
              onClick={closeModal}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Hero;
