import React, { useState, useEffect, useCallback } from "react";
import { motion } from "framer-motion";
import Lottie from "react-lottie";
import Hero from "../components/Hero";
import Service from "../components/Service";
import TechWeLove from "../components/TechWeLove";
import Footer from "../components/Footer";
import Testimonials from "../components/Testimonials";
import arrowUpAnimation from "../assets/animation/up_arrow.json";
import useDocumentTitle from "../components/DocTitle";
import WhyUranus from "../components/WhyUranus";
import ScrollComponent from "../components/ScrollComponent";
import DownloadApp from "../components/DownloadApp";

const Homepage = () => {
  useDocumentTitle("Home - Uranus Tech Nepal");

  const [showScroll, setShowScroll] = useState(false);

  // Function to check the scroll position and show the button
  const checkScrollTop = useCallback(() => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  }, [showScroll]);

  // Function to scroll to the top smoothly
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
    return () => {
      window.removeEventListener("scroll", checkScrollTop);
    };
  }, [checkScrollTop]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: arrowUpAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="overflow-x-hidden overflow-y-hidden">
      <motion.div
        initial={{ x: -50, opacity: 0 }}
        whileInView={{ x: 0, opacity: 1 }}
        transition={{ delay: 0.1, duration: 0.5 }}
      >
        <Hero />
      </motion.div>

      {/* <motion.div
        initial={{ x: -50, opacity: 0 }}
        whileInView={{ x: 0, opacity: 1 }}
        transition={{ delay: 0.1, duration: 0.5 }}
      >
        <TrustedBy />
      </motion.div> */}
      <motion.div
        initial={{ x: -50, opacity: 0 }}
        whileInView={{ x: 0, opacity: 1 }}
        transition={{ delay: 0.1, duration: 0.5 }}
      >
        <ScrollComponent />
      </motion.div>

      <motion.div
        initial={{ x: -50, opacity: 0 }}
        whileInView={{ x: 0, opacity: 1 }}
        transition={{ delay: 0.1, duration: 0.5 }}
      >
        <Service />
      </motion.div>

      <motion.div
        initial={{ x: -50, opacity: 0 }}
        whileInView={{ x: 0, opacity: 1 }}
        transition={{ delay: 0.1, duration: 0.5 }}
      >
        <WhyUranus />
      </motion.div>

      <motion.div
        initial={{ x: -50, opacity: 0 }}
        whileInView={{ x: 0, opacity: 1 }}
        transition={{ delay: 0.1, duration: 0.5 }}
      >
        <Testimonials />
      </motion.div>
      <motion.div
        initial={{ x: -50, opacity: 0 }}
        whileInView={{ x: 0, opacity: 1 }}
        transition={{ delay: 0.1, duration: 0.5 }}
      >
        <DownloadApp />
      </motion.div>
      <motion.div
        initial={{ x: -50, opacity: 0 }}
        whileInView={{ x: 0, opacity: 1 }}
        transition={{ delay: 0.1, duration: 0.5 }}
      >
        <TechWeLove />
      </motion.div>

      <motion.div
        initial={{ y: 20, opacity: 0.5 }}
        whileInView={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.1, duration: 0.4 }}
      >
        <Footer />
      </motion.div>

      {/* Floating Back to Top Button */}
      {showScroll && (
        <motion.div
          className="z-200 fixed bottom-5 right-5 bg-scroll text-white p-3 rounded-full shadow-lg cursor-pointer"
          onClick={scrollToTop}
          whileHover={{ scale: 1.2 }}
          transition={{ duration: 0.2 }}
        >
          <Lottie options={defaultOptions} height={50} width={50} />
        </motion.div>
      )}
    </div>
  );
};

export default Homepage;
