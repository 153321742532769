import React, { useState } from "react";
import { motion } from "framer-motion";
import dfaimage from "../assets/dfaimage.png";
import mpassbook from "../assets/mpassbook.png";
import InfoFooter from "../components/InfoFooter";
import AlertDialog from "../components/AlertDialog";
import useDocumentTitle from "../components/DocTitle";
import { useNavigate } from "react-router-dom";

const OurPortfolio = () => {
  useDocumentTitle("Our Portfolio - Uranus Tech Nepal");

  const projects = [
    {
      name: "Core Banking Solution (CBS) for 30+ Institutions",
      description:
        "A comprehensive banking solution implemented in over 30 financial institutions.",
      imageUrl:
        "https://static.wixstatic.com/media/f9b0fa_917798574b3d4d978d0f515c2ba97235~mv2.jpg/v1/fill/w_640,h_360,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/f9b0fa_917798574b3d4d978d0f515c2ba97235~mv2.jpg",
      _id: "CBS",
    },
    {
      name: "Human Resource Management",
      description:
        "An efficient HR management system to streamline HR processes.",
      imageUrl:
        "https://miro.medium.com/v2/resize:fit:1200/1*aoz09QCaLMxwTZ5osWuItw.jpeg",
      _id: "UranusHRM",
    },
    {
      name: "Digital Field Application",
      description:
        "A mobile solution for field data collection and management.",
      imageUrl: dfaimage,
      _id: "DFA",
    },
    {
      name: "mPassbook",
      description:
        "A digital passbook application for convenient account management.",
      imageUrl: mpassbook,
      _id: "mPassbook",
    },

    {
      name: "Fixed Assets Management Information System",
      description:
        "A system developed for efficient pension distribution by the Indian Embassy.",
      imageUrl:
        "https://newsroom.gy/wp-content/uploads/2016/08/pension_14735595_07_6_1469c.jpg",
      _id: "FAMIS",
    },
    {
      name: "Uranus Disability / Old Age Care Management Software",
      description:
        "A digital passbook application for convenient account management.",
      imageUrl: mpassbook,
      _id: "UCare",
    },
    {
      name: "Insurance Management Software for Banks",
      description:
        "Software to streamline non-life insurance loan administration for banks.",
      imageUrl: mpassbook,
      _id: "IMS",
    },
    {
      name: "Daily Progress Management Software for Banks",
      description:
        "Software to manage daily banking operations with reporting and staff tracking.",
      imageUrl: mpassbook,
      _id: "DPMS",
    },
    {
      name: "Pension Disbursement Software for Embassy of India",
      description:
        "Software for accurate pension disbursement for Indian military retirees.",
      imageUrl: mpassbook,
      _id: "PDS",
    },
    {
      name: "International Trade Statistic Software for Nepal Rastra Bank",
      description:
        "Software for analyzing international trade statistics to aid economic decisions.",
      imageUrl: mpassbook,
      _id: "ITSS",
    },

    {
      name: "Conference Management Web Solution with Mobile App",
      description:
        "A web and mobile solution for managing conferences organized by ACSIC.",
      imageUrl: mpassbook,
      _id: "ACSIC",
    },
  ];
  const navigate = useNavigate();

  const handleLearnMore = (projectId) => {
    navigate(`/products/${projectId}`);
  };
  return (
    <div className="flex flex-col w-full min-h-screen relative ">
      <motion.div
        className="px-4 md:px-10 lg:px-20 py-10 "
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <motion.h1
          className="font-bold text-primary text-4xl text-center mb-2"
          initial={{ scale: 0.8 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          Our Portfolio
        </motion.h1>
        <motion.p
          className="text-center text-gray-700 text-lg mb-10"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7 }}
        >
          Explore some of the projects we've developed to help businesses
          succeed.
        </motion.p>

        <div className="w-full mx-auto space-y-8 px-4">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
            {projects.map((project, index) => (
              <motion.div
                initial={{ y: 50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{
                  delay: 0.1 * index,
                  duration: 0.5,
                }}
                key={index}
                className="relative bg-white border border-gray-200 shadow-md rounded-lg overflow-hidden transform transition-all duration-500 hover:shadow-lg hover:scale-105 w-full h-auto flex flex-col"
              >
                {/* Image Section */}
                <div className="relative w-full h-40 overflow-hidden group">
                  <img
                    src={project.imageUrl}
                    alt={project.name}
                    className="w-full h-full object-cover transition-transform duration-500 transform group-hover:scale-110"
                  />
                </div>

                {/* Content Section */}
                <div className="p-4 flex flex-col flex-grow text-center">
                  <h2 className="text-lg font-semibold text-gray-800 mb-2">
                    {project.name}
                  </h2>
                  <p className="text-sm text-gray-600 line-clamp-3 flex-grow">
                    {project.description}
                  </p>

                  <button
                    onClick={() => handleLearnMore(project._id)}
                    className="mt-4 inline-block w-full text-center px-4 py-2 text-white bg-primary rounded-lg transition-transform duration-300 hover:scale-105 hover:bg-primary-dark"
                  >
                    Learn More
                  </button>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </motion.div>

      <InfoFooter />
    </div>
  );
};

export default OurPortfolio;
